'use client'

import React, { useEffect, useState } from 'react'
import { goToTop } from '@lib/services/pageService'
import UserCard from '@components/user-card'
import { useRecoilState } from 'recoil'
import { siteState } from '@atoms/index'
import Filters from '@components/filters'
import { API_USERS } from '@lib/api-routes'
import { config } from '@lib/config'

function MemberList(props) {
    const [siteData] = useRecoilState(siteState)
    const [loading, setLoading] = useState(true)
    const [members, setMembers] = useState<any>(null)
    const [pagination, setPagination] = useState<any>(false)
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: props.limit ? props.limit : 16,
        offset: 0,
        role: 'vip',
        filter: null,
        orderBy: props.orderby ? props.orderby : null,
        direction: null,
        search: null,
        niche: siteData?.type,
    })

    const calculateOffset = (page: any, limit: any) => (page - 1) * limit

    useEffect(() => {
        requestMembers()
    }, [query])

    useEffect(() => {
        if (query.filters) {
            requestMembers()
        }
    }, [query.filters])

    function requestMembers() {
        setLoading(true)
        const params = new URLSearchParams({
            limit: query.limit,
            offset: query.offset,
            page: query.page,
            role: query.role,
            orderBy: query.orderBy,
            direction: query.direction,
            niche: query.niche,
            ...(query.search ? { search: query.search } : ''),
        })

        let hasFilter = false

        // Add filter object parameters
        if (query.filter && typeof query.filter === 'object') {
            Object.entries(query.filter).forEach(([key, value]: any) => {
                if (value !== null && value !== undefined) {
                    params.append(key, value.toLowerCase())
                    hasFilter = true
                }
            })
        }

        // Add filter=true if filter object has any values
        if (hasFilter) {
            params.append('filter', 'true')
        }

        if (params.get('gender') === '') {
            params.delete('gender')
            params.delete('filter')
        }

        const url = `${process.env.API_URL_V2}${API_USERS}?${params.toString()}`

        fetch(url)
            .then((res) => res.json())
            .then((response) => {
                if (response.error) {
                    console.warn(response.error)
                    setLoading(false)
                } else {
                    setMembers(response.items)
                    setPagination(response.pagination)
                    setLoading(false)
                    goToTop()
                }
            })
            .catch((error) => {
                console.error(error)
                setLoading(false)
            })
    }

    const onFilterChange = (e) => {
        setQuery({
            ...query,
            page: 1,
            filter: {
                ...query.filters,
                [e.target.name]: e.target.value,
            },
        })
    }

    const onFilterReset = (e) => {
        setQuery({
            ...query,
            page: 1,
            filter: null,
            offset: 0
        })
    }

    const onPageChange = (newPage: any) => {
        const newOffset = calculateOffset(newPage, query.limit)
        setQuery((prev: any) => {
            return { ...prev, page: newPage, offset: newOffset }
        })
    }

    return (
        <>
            {props?.showFilters && (
                <div
                    className={
                        config.featureFlags.newFilters
                            ? 'max-w-screen-lg mx-auto'
                            : 'max-w-md mx-auto'
                    }
                >
                    <Filters
                        onFilterChange={onFilterChange}
                        onFilterReset={onFilterReset}
                    />
                </div>
            )}
            <div className="max-w-screen-lg mx-auto flex flex-wrap justify-center mb-8">
                {loading && 'loading...'}

                {!members && !loading && (
                    <p>
                        Sorry, there are currently no members in your area.
                        <br />
                        Click the button below to view all members on the site
                    </p>
                )}
                {members && members.length > 0 && !loading && (
                    <>
                        <div
                            className="grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                            // className={`grid ${`${
                            //     props.grid
                            //         ? `grid-cols-${props.grid}`
                            //         : 'grid-cols-2'
                            // }`} ${' '}

                            // ${
                            //     props.gridsm
                            //         ? `sm:grid-cols-${props.gridsm}`
                            //         : 'sm:grid-cols-2'
                            // }${' '}
                            // ${
                            //     props.gridmd
                            //         ? `md:grid-cols-${props.gridmd}`
                            //         : 'md:grid-cols-4'
                            // }${' '}
                            // ${
                            //     props.gridlg
                            //         ? `lg:grid-cols-${props.gridlg}`
                            //         : 'lg:grid-cols-4'
                            // } gap-8 mb-8`}
                        >
                            {!members || members.length === 0
                                ? ''
                                : Object.values(members).map(
                                      (member: any, i: number) => {
                                          member.image = member.media.find(
                                              (m: any) => m.is_primary === 1
                                          )?.url
                                          return (
                                              <UserCard key={i} {...member} />
                                          )
                                      }
                                  )}
                        </div>
                    </>
                )}
            </div>

            {props.paged && (
                <div className="grid grid-cols-2 justify-center gap-8">
                    <div className="flex justify-self-end">
                        {query.page !== 1 && (
                            <button
                                className="inline-flex items-center border border-black bg-black text-white rounded-md font-bold py-4 px-6 hover:bg-grey-500 hover:text-white"
                                onClick={() => onPageChange(query.page - 1)}
                                data-direction="prev"
                            >
                                <svg
                                    className="h-5 w-5 mr-2 fill-current"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="-49 141 512 512"
                                    xmlSpace="preserve"
                                    fill="white"
                                >
                                    <path
                                        id="XMLID_10_"
                                        d="M438,372H36.355l72.822-72.822c9.763-9.763,9.763-25.592,0-35.355c-9.763-9.764-25.593-9.762-35.355,0 l-115.5,115.5C-46.366,384.01-49,390.369-49,397s2.634,12.989,7.322,17.678l115.5,115.5c9.763,9.762,25.593,9.763,35.355,0 c9.763-9.763,9.763-25.592,0-35.355L36.355,422H438c13.808,0,25-11.193,25-25S451.808,372,438,372z"
                                    ></path>
                                </svg>
                                Previous page
                            </button>
                        )}
                    </div>
                    <div className="flex justify-self-start">
                        {pagination.totalPages !== pagination.currentPage && (
                            <button
                                className="inline-flex items-center border border-black bg-black text-white rounded-md font-bold py-4 px-6 hover:bg-grey-500 hover:text-white"
                                onClick={() => onPageChange(query.page + 1)}
                                data-direction="next"
                            >
                                Next page
                                <svg
                                    className="h-5 w-5 ml-2 fill-current"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="-49 141 512 512"
                                    xmlSpace="preserve"
                                    fill="white"
                                >
                                    <path
                                        id="XMLID_11_"
                                        d="M-24,422h401.645l-72.822,72.822c-9.763,9.763-9.763,25.592,0,35.355c9.763,9.764,25.593,9.762,35.355,0
                            l115.5-115.5C460.366,409.989,463,403.63,463,397s-2.634-12.989-7.322-17.678l-115.5-115.5c-9.763-9.762-25.593-9.763-35.355,0
                            c-9.763,9.763-9.763,25.592,0,35.355l72.822,72.822H-24c-13.808,0-25,11.193-25,25S-37.808,422-24,422z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default MemberList
